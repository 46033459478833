$( document ).ready(function() {

    $( '#main-sidebar' ).sliderPro({
        width: '100%',
        height: 550,
        arrows: true,
        buttons: false,
        waitForLayers: true,
        autoplay: true,
        autoplayDelay: 8000,
        autoScaleLayers: false,
        breakpoints: {
            360: {
                height: 550,
                centerImage: false
            },
            640: {
                height: 550,
                centerImage: false
            }
        }
    });


      $('a[href^="#"]').on('click',function (e) {
        if(!$(this).hasClass("exclude")) {
          e.preventDefault();
          var target = this.hash;
          var $target = $(target);
          $('html, body').stop().animate({
              'scrollTop': $target.offset().top - 100
          }, 900, 'swing', function () {
          });
        }
      });

    $("#contact").validate({
        submitHandler: function(form) {
            $(form).ajaxSubmit({target: '#contact-response'});
        }
    });

    if($(".sidebars").length != 0)
    {
        var sides = ["left", "top", "right", "bottom"];
      
        for (var i = 0; i < sides.length; ++i) {
            var cSide = sides[i];
            $(".sidebar." + cSide).sidebar({side: cSide});
        }

        $(".sidebar-btn[data-action]").on("click", function () {
            var $this = $(this);
            var action = $this.attr("data-action");
            var side = $this.attr("data-side");
            $(".sidebar." + side).trigger("sidebar:" + action);
            return false;
        });
    }
});